import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';

const Container = styled.div`
  padding: 16px;
  border-radius: 16px;
  font-weight: 600;
`;
const List = styled.ul``;
const Pro = styled.li`
  padding: 6px;
  font-size: 1.7rem;
  span {
    margin-left: 16px;
  }
`;

const ProList = ({ pros, bg, fg }) => (
  <Container style={{ backgroundColor: bg || '#DDD' }}>
    <List>
      {pros.map(pro => (
        <Pro key={pro}>
          <FontAwesomeIcon icon={faArrowUp} style={{ color: fg || '#999' }} />
          <span>{pro}</span>
        </Pro>
      ))}
    </List>
  </Container>
);

ProList.propTypes = {
  pros: PropTypes.arrayOf(PropTypes.string).isRequired,
  bg: PropTypes.string,
  fg: PropTypes.string,
};

export default ProList;
