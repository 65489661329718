import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed from 'react-pose';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import IO from 'components/common/io';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import MEDIA from 'helpers/mediaTemplates';
import theme from 'constants/theme';
import Section from 'components/common/Section';

const Fledlings = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 4rem;
  padding: 0 4rem;
  margin: 2rem 0;
  ${MEDIA.TABLET`
    display: block;
  `};
`;

const Fledge = styled(
  posed.div({
    hidden: {
      opacity: 0,
      x: 0,
      y: 40,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  })
)`
  cursor: pointer;
  width: 200px;
  display: flex;
  flex-flow: column;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 3px 3px 50px 5px rgba(47, 0, 22, 0.1);
  font-size: 1.3rem;
  p {
    font-weight: 600;
    padding: 0px 14px;
    line-height: 1.4;
    text-align: center;
  }
  span {
    margin-bottom: 10px;
    align-self: center;
    color: #999;
  }
  &:hover {
    span {
      color: #555;
    }
  }
`;

const OurFledglings = ({ data }) => (
  <Section
    data={data.ourFledglings}
    bg={theme.light}
    iconColor={theme.faintText}
  >
    <Fledlings>
      {data.fledglings.map(fledge => (
        <IO key={fledge.name}>
          {({ isVisible }) => (
            <a href={fledge.link} target="_blank" rel="noopener noreferrer">
              <Fledge pose={isVisible ? 'visible' : 'hidden'}>
                <div style={{ flex: 1 }}>
                  <Img
                    fluid={fledge.logo ? fledge.logo.childImageSharp.fluid : {}}
                    alt={fledge.name}
                  />
                </div>
                <p>{fledge.description}</p>
                <span>
                  Learn more
                  <FontAwesomeIcon
                    style={{ fontSize: 12, marginLeft: 8 }}
                    icon={faArrowRight}
                  />
                </span>
              </Fledge>
            </a>
          )}
        </IO>
      ))}
    </Fledlings>
  </Section>
);

OurFledglings.propTypes = {
  data: PropTypes.object.isRequired,
};

const OurFledglingsWithQuery = props => (
  <StaticQuery
    query={graphql`
      query OurFledglingsQuery {
        homeYaml {
          ourFledglings {
            icon
            title
            description
          }
          fledglings {
            name
            logo {
              childImageSharp {
                fluid(maxWidth: 480, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            description
            link
          }
        }
      }
    `}
    render={({ homeYaml }) => <OurFledglings data={homeYaml} {...props} />}
  />
);

export default OurFledglingsWithQuery;
