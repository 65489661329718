import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import MEDIA from 'helpers/mediaTemplates';
import theme from 'constants/theme';
import Section from 'components/common/Section';
import ProList from 'components/common/ProList';
import Actionable from 'components/common/Actionable';

const Provided = styled.div`
  display: flex;
  padding: 0 4rem;
  margin: 2rem 0;
  ${MEDIA.TABLET`
    display: block;
  `};
`;

const AndMore = styled.div`
  display: flex;
  flex-flow: column;
  margin: 0px 22px 0px 22px;
  ${MEDIA.TABLET`

    margin: 22px;
  `};
  span {
    font-size: 1.3rem;
    color: ${theme.royalText};
    margin: 14px 0px;
  }
`;

const Shuffle = styled.ul`
  display: flex;
  list-style: none;
  max-width: 300px;
  flex-wrap: wrap;
  li {
    color: #676767;
    font-size: 1.1rem;
    margin-right: 20px;
    line-height: 1.5;
    font-weight: 500;
  }
`;

const WhatWeOffer = ({ data }) => (
  <Section
    data={data.whatWeOffer}
    bg={theme.white}
    iconColor={theme.fainterText}
  >
    <Provided>
      <ProList
        pros={data.whatWeOffer.pros}
        bg={theme.light}
        fg={theme.royalText}
      />
      <AndMore>
        <span>and more...</span>
        <Shuffle>
          {data.whatWeOffer.morePros.map(pro => (
            <li key={pro}>{pro}</li>
          ))}
        </Shuffle>
      </AndMore>
    </Provided>
    <Actionable
      data={data.whatWeOffer.callToAction}
      bg={theme.royal}
      fg={theme.whiteText}
    />
  </Section>
);

WhatWeOffer.propTypes = {
  data: PropTypes.object.isRequired,
};

const WhatWeOfferWithQuery = props => (
  <StaticQuery
    query={graphql`
      query WhatWeOfferQuery {
        homeYaml {
          whatWeOffer {
            icon
            title
            description
            pros
            morePros
            callToAction {
              call
              action
              link
            }
          }
        }
      }
    `}
    render={({ homeYaml }) => <WhatWeOffer data={homeYaml} {...props} />}
  />
);

export default WhatWeOfferWithQuery;
