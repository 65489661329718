import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import MEDIA from 'helpers/mediaTemplates';
import Modal from 'components/common/Modal';
import theme from 'constants/theme';

const Caption = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  ${MEDIA.TABLET`
    margin-bottom: 3rem;
  `};
`;
const Name = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 600;
  color: #342a28;
  margin: 3rem 2rem 0.6rem;
  font-family: 'TiemposHeadline';
`;
const Role = styled.span`
  color: ${theme.royalText};
  font-size: 1.3rem;
`;
const ProfilePhoto = styled(Img)`
  border-radius: 50%;
`;
export const MoreButton = styled.button`
  background: none;
  border: none;
  color: #757575;
  cursor: pointer;
  padding: 1rem 2rem;
  transition: 0.15s color ease;
  outline: none;
  &:active,
  &:hover {
    color: ${theme.darkText};
  }
  svg {
    font-size: 0.85em;
  }
`;
const Bio = styled.div`
  font-family: 'Nunito Sans';
  padding: 24px;
  h3 {
    font-size: 3.4rem;
    margin-bottom: 6px;
  }
  h4 {
    font-family: 'Nunito Sans';
    color: ${theme.royalText};
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 1.7rem;
  }
  p {
    margin: 10px 0px;
    line-height: 1.6;
    font-size: 1.6rem;
  }
`;

class Member extends React.PureComponent {
  state = {
    modalVisible: false,
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const { data } = this.props;
    return (
      <figure>
        <ProfilePhoto
          fluid={data.image ? data.image.childImageSharp.fluid : {}}
          alt={data.name}
        />
        <Caption>
          <Name>{data.name}</Name>
          <Role>{data.role}</Role>

          <MoreButton onClick={this.showModal}>
            Read more
            <FontAwesomeIcon style={{ marginLeft: 6 }} icon={faArrowRight} />
          </MoreButton>

          <Modal open={this.state.modalVisible} hideModal={this.hideModal}>
            <Bio>
              <h3>{data.name}</h3>
              <h4>{data.role}</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: data.bio.childMarkdownRemark.html,
                }}
              />
            </Bio>
          </Modal>
        </Caption>
      </figure>
    );
  }
}

Member.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Member;
