import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import theme from 'constants/theme';
import Section from 'components/common/Section';
import Team from 'components/team/Team';

const WhoWeAre = ({ data }) => (
  <Section data={data.whoWeAre} bg={theme.light} iconColor={theme.faintText}>
    <Team members={data.team} />
  </Section>
);

WhoWeAre.propTypes = {
  data: PropTypes.object.isRequired,
};

const WhoWeAreWithQuery = props => (
  <StaticQuery
    query={graphql`
      query WhoWeAreQuery {
        homeYaml {
          whoWeAre {
            icon
            title
            description
          }
          team {
            name
            image {
              childImageSharp {
                fluid(maxWidth: 256, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            role
            bio {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `}
    render={({ homeYaml }) => <WhoWeAre data={homeYaml} {...props} />}
  />
);

export default WhoWeAreWithQuery;
