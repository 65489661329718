import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import theme from 'constants/theme';
import Section from 'components/common/Section';
import Actionable from 'components/common/Actionable';

const BecomePartner = ({ data }) => (
  <Section
    data={data.becomePartner}
    bg={theme.royal}
    fg={theme.whiteText}
    iconColor={theme.purplyText}
  >
    <Actionable
      data={data.becomePartner.callToAction}
      bg={theme.white}
      fg={theme.royalText}
    />
  </Section>
);

BecomePartner.propTypes = {
  data: PropTypes.object.isRequired,
};

const BecomePartnerWithQuery = props => (
  <StaticQuery
    query={graphql`
      query BecomePartnerQuery {
        homeYaml {
          becomePartner {
            icon
            title
            description
            callToAction {
              call
              action
              link
            }
          }
        }
      }
    `}
    render={({ homeYaml }) => <BecomePartner data={homeYaml} {...props} />}
  />
);

export default BecomePartnerWithQuery;
