import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';

import theme from 'constants/theme';

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;
const ButtonLabel = styled.span`
  font-weight: 600;
  font-size: 13px;
`;
const Button = styled.button`
  border: none;
  margin-top: 10px;
  padding: 12px 8px 12px 16px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.15s;
  opacity: 1;
  font-weight: 600;
  ${({ bg, fg }) => css`
    background-color: ${bg};
    color: ${fg};
  `};
  svg {
    font-size: 90%;
    vertical-align: 2%;
    transition: 0.15s;
    margin-left: 12px;
    margin-right: 12px;
  }
  &:hover {
    opacity: 0.9;
    svg {
      margin-left: 20px;
      margin-right: 4px;
    }
  }
`;

const Actionable = ({ data, bg, fg }) => (
  <Container>
    <ButtonLabel style={{ color: bg || theme.darkText }}>
      {data.call}
    </ButtonLabel>
    <a href={data.link}>
      <Button bg={bg || theme.white} fg={fg || theme.darkText}>
        <span>{data.action}</span>
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </a>
  </Container>
);

Actionable.propTypes = {
  data: PropTypes.object.isRequired,
  bg: PropTypes.string,
  fg: PropTypes.string,
};

export default Actionable;
