import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from 'constants/theme';

const Container = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  padding: 80px 0;
  overflow: hidden;
`;
const Column = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;
const SectionIcon = styled(FontAwesomeIcon)`
  font-size: 4rem;
`;
const Title = styled.h2`
  font-size: 4rem;
  margin: 40px 0;
`;
const Description = styled.p`
  max-width: 500px;
  text-align: center;
  line-height: 1.5;
  padding: 0 30px;
`;

const Section = ({ children, data, bg, fg, iconColor }) => (
  <Container style={{ backgroundColor: bg, color: fg || theme.darkText }}>
    <Column>
      <SectionIcon icon={data.icon} style={{ color: iconColor || '#999' }} />
      <Title>{data.title}</Title>
      <Description>{data.description}</Description>
      {children}
    </Column>
  </Container>
);

Section.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  bg: PropTypes.string.isRequired,
  fg: PropTypes.string,
  iconColor: PropTypes.string,
};

export default Section;
