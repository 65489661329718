import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import MEDIA from 'helpers/mediaTemplates';
import IO from 'components/common/io';
import Member from 'components/team/Member';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  justify-content: space-evenly;
  padding: 0 4rem;
  margin: 2rem 0;
  ${MEDIA.TABLET`
    display: block;
  `};
`;
const PopIn = posed.div({
  hidden: {
    opacity: 0,
    x: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
  },
});

const Team = ({ members }) => (
  <IO>
    {({ isVisible }) => (
      <PopIn pose={isVisible ? 'visible' : 'hidden'}>
        <Container>
          {members.map((member, i) => (
            <Member key={i} data={member} />
          ))}
        </Container>
      </PopIn>
    )}
  </IO>
);

Team.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Team;
