import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import MEDIA from 'helpers/mediaTemplates';
import Layout from 'components/common/Layout';
import WhoWeAre from 'components/sections/WhoWeAre';
import WhatWeOffer from 'components/sections/WhatWeOffer';
import OurFledglings from 'components/sections/OurFledglings';
import BecomePartner from 'components/sections/BecomePartner';
// import lightWave from 'images/lightWaveOptim.svg';

const Splash = styled.div`
  display: flex;
  height: 84vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;
const Waves = styled.div`
  position: relative;
  height: 100px;
  width: 100vw;
  margin-bottom: -2px;
`;
const Wave = styled.div`
  position: absolute;
  top: 0;
  height: 100px;
  width: 100%;
`;
const BackWave = styled(Wave)`
  background: url('img/backWave.svg');
  background-size: cover;
  background-position: 50% 0;
`;
const FrontWave = styled(Wave)`
  background: url('img/frontWave.svg');
  background-size: cover;
  background-position: 50% 0;
`;

// const Branding = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-flow: column;
//   font-size: 5rem;
// `;
const Fig = styled.figure`
  width: 360px;
  ${MEDIA.TABLET`
    width: 300px;
  `};
  ${MEDIA.MOBILE`
    width: 80vw;
  `};
`;

const Index = ({ data: { homeYaml } }) => (
  <Layout>
    <Splash>
      <Fig>
        <Img
          fluid={homeYaml.logo ? homeYaml.logo.childImageSharp.fluid : {}}
          alt="FlameFinch"
        />
      </Fig>
    </Splash>

    <Waves>
      <BackWave />
      <FrontWave />
    </Waves>

    <WhoWeAre />
    <WhatWeOffer />
    <OurFledglings />
    <BecomePartner />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeYaml {
      title
      logo {
        childImageSharp {
          fluid(maxWidth: 512, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
